import arrowIcon from '../../assets/arrow.svg'
import styled, {keyframes} from 'styled-components'
import {DefaultTextColor} from '../../ui/palette'
import {device} from '../../ui/device'

const OptionWrapper = styled.div<{selected?: boolean; blur?: boolean}>`
  display: flex;
  align-items: center;
  transition: all 2s;
  justify-content: space-between;
  width: 100%;

  ${(props) => props.blur && 'filter: blur(4px);'}

  @media ${device.tablet} {
    filter: blur(4px);
    width: auto;

    &:hover {
      filter: none;
    }
  }
`
const OptionTitle = styled.div`
  font-family: 'Branch';
  font-weight: 400;

  transition: all 2s;

  &::first-letter {
    font-family: 'Dirtyline36Daysoftype2022';
    font-weight: 400;
    text-transform: capitalize;
    font-feature-settings: 'salt' on, 'ss01' on, 'ss03' on;
  }

  font-weight: 400;
  font-size: 32px;
  line-height: 35px;

  @media ${device.tablet} {
    font-size: 64px;
    line-height: 92px;

    ${OptionWrapper}:hover & {
      font-size: 80px;

      &::first-letter {
        font-size: 88px;
      }
    }

    &::first-letter {
      font-size: 80px;
      line-height: 88px;
    }
  }

  ${DefaultTextColor}
`

const OptionInfo = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

const ContinueButton = styled.div`
  color: #e3f36c;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  animation: 2s ${fadeIn} ease-out;
`

const ArrowIcon = styled.div`
  background-image: url(${arrowIcon});
  width: 21px;
  height: 14px;
  margin-left: 16px;
`

const OptionDescription = styled.div`
  font-size: 20px;
  line-height: 26px;
  font-weight: 200;
  ${DefaultTextColor}
`
export {
  OptionWrapper,
  OptionDescription,
  OptionTitle,
  OptionInfo,
  ContinueButton,
  ArrowIcon,
}
