export default class AnswerOption {
  public title!: string

  public description?: string
  public sound?: string
  public video?: string

  constructor(data: Partial<AnswerOption>) {
    Object.assign(this, data)
  }
}
