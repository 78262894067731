import {RefObject, useEffect} from 'react'

const useOutsideClick = (
  ref: RefObject<HTMLDivElement | null>,
  handler: () => void
) => {
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        handler()
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [handler, ref])
}

export default useOutsideClick
