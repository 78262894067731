import {createGlobalStyle} from 'styled-components'

export default createGlobalStyle`
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }

  body {
    font-family: 'IBM Plex Sans Condensed', sans-serif;
  }

  html, body {
    margin: 0; 
    height: 100%; 
    overflow: hidden;
  }

  a {
   color: inherit; 
   text-decoration: inherit; 
  }
`
