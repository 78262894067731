import {useCallback, useState} from 'react'
import AnswerOption from '../data/AnswerOption'
import Question from '../data/Question'
import {useNavigate} from 'react-router-dom'
import data from '../data/json/questions.json'

// export const questions = [
//   new Question({
//     title: 'I Fantasize of..',
//     // зажигалка
//     video:
//       'https://ia902607.us.archive.org/35/items/pexels-anastasia-shuraeva-6236526-4096x-2160-24fps-2/pexels-kelly-2548212-3840x2160-24fps%20%281%29.mp4',
//     options: [
//       new AnswerOption({title: 'Cosy'}),
//       new AnswerOption({title: 'Lux'}),
//       new AnswerOption({
//         title: 'Sex Party',
//         // ноги
//         video:
//           'https://ia802607.us.archive.org/35/items/pexels-anastasia-shuraeva-6236526-4096x-2160-24fps-2/output%28compress-video-online.com%29.mp4',
//       }),
//       new AnswerOption({
//         title: 'Nature',
//         sound:
//           'https://ia800108.us.archive.org/14/items/78_when-a-woman-loves-a-man_billie-holiday-and-her-orchestra-billie-holiday-buck-clayt_gbia0031202/06%20-%20A%20Sailboat%20in%20the%20Moonlight%20-%20Billie%20Holiday%20and%20her%20Orchestra.mp3',
//       }),
//       new AnswerOption({title: 'Adventure'}),
//     ],
//   }),
//   new Question({
//     title: 'I feel more..',
//     // video:
//     //   'https://ia902606.us.archive.org/25/items/pexels-cottonbro-studio-4156329-4096x-2160-50fps-1_202305/pexels-cottonbro-studio-4156329-4096x2160-50fps%20%281%29.mp4',
//     options: [
//       new AnswerOption({title: 'Masculine'}),
//       new AnswerOption({title: 'Feminine', description: 'Soft and touchy'}),
//       new AnswerOption({title: 'Neither'}),
//     ],
//   }),
// ]

// {
//   "title": "What brings you pleasure besides sex?",
//   "video": "https://ia802607.us.archive.org/35/items/pexels-anastasia-shuraeva-6236526-4096x-2160-24fps-2/output%28compress-video-online.com%29.mp4",
//   "options": [
//     {"title": "Senses"},
//     {"title": "Competition"},
//     {"title": "Communication"},
//     {"title": "Rebel"},
//     {"title": "Comfort"}
//   ]
// },

export const questions = data.data.map(
  (question) =>
    new Question({
      ...question,
      options: question.options.map((option) => new AnswerOption(option)),
    })
)

const useQuestions = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)
  const navigate = useNavigate()

  const saveOption = (option: AnswerOption) => {
    console.log('save', option)
  }

  const next = useCallback(
    (option: AnswerOption) => {
      saveOption(option)
      setCurrentIndex((prev) => {
        if (prev < questions.length - 1) {
          return prev + 1
        }

        navigate('/result')
        return 0
      })
    },
    [navigate]
  )

  return {currentIndex, next, questions}
}

export default useQuestions
