import {isTablet} from '../ui/device'
import useCurrentPage from './useCurrentPage'

const useData = () => {
  const page = useCurrentPage()

  const {backgroundImage, video, sound, title} = page

  return {
    video,
    sound,
    image:
      backgroundImage?.s && !isTablet()
        ? backgroundImage.s
        : backgroundImage?.m,
    title,
  }
}

// videos
// https://ia902606.us.archive.org/25/items/pexels-cottonbro-studio-4156329-4096x-2160-50fps-1_202305/pexels-anastasia-shuraeva-6236526-4096x2160-24fps.mp4
// https://ia902606.us.archive.org/25/items/pexels-cottonbro-studio-4156329-4096x-2160-50fps-1_202305/pexels-cottonbro-studio-4156329-4096x2160-50fps%20%281%29.mp4
// https://ia802606.us.archive.org/25/items/pexels-cottonbro-studio-4156329-4096x-2160-50fps-1_202305/pexels-kelly-2548212-3840x2160-24fps.mp4

//converted videos
// https://ia802607.us.archive.org/35/items/pexels-anastasia-shuraeva-6236526-4096x-2160-24fps-2/pexels-anastasia-shuraeva-6236526-4096x2160-24fps%20%281%29.mp4
// https://ia902607.us.archive.org/35/items/pexels-anastasia-shuraeva-6236526-4096x-2160-24fps-2/pexels-kelly-2548212-3840x2160-24fps%20%281%29.mp4
// https://ia802607.us.archive.org/35/items/pexels-anastasia-shuraeva-6236526-4096x-2160-24fps-2/output%28compress-video-online.com%29.mp4
export default useData
