import styled from 'styled-components'
import {device} from '../../ui/device'
import Button from '../../components/Button'

const Image = styled.img`
  width: 100vw;
  padding: 0 28px 0 38px;
  margin-bottom: -18px;

  @media ${device.tablet} {
    width: 582px;
    height: 317px;
    padding: 0;
    margin-bottom: -60px;
  }
`

const Description = styled.h2`
  font-size: 17px;
  font-weight: 300;

  background: linear-gradient(
    122.15deg,
    #feffe2 9.69%,
    #e6d189 90.36%,
    #291a15 144.67%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  text-align: center;

  @media ${device.tablet} {
    font-size: 1.5em;
  }
`

const LogoWrapper = styled.div`
  margin-bottom: 124px;
`

const StyledButton = styled(Button)`
  width: 132px;
  height: 82px;
  text-transform: uppercase;
  font-size: 18px;

  @media ${device.tablet} {
    width: 185px;
    height: 108px;
  }
`

export {Image, LogoWrapper, Description, StyledButton as Button}
