import Siriwave from 'react-siriwave'
import styled from 'styled-components'
import {FC} from 'react'
import {isTablet} from '../../ui/device'
import useSound from '../../hooks/useSound'

type Props = {
  bottomPosition?: number
}

const SoundWrapper = styled.div<{bottomPosition: number}>`
  position: absolute;
  bottom: ${(props) => props.bottomPosition}px;
  width: 100%;
  display: flex;
  justify-content: center;
`

// https://www.npmjs.com/package/react-siriwave
const SoundWave: FC<Props> = ({bottomPosition = 0}) => {
  const {mute} = useSound()
  return (
    <SoundWrapper bottomPosition={bottomPosition}>
      <Siriwave
        width={isTablet() ? 433 : 231}
        height={isTablet() ? 150 : 80}
        theme="ios9"
        amplitude={mute ? 0 : undefined}
      />
    </SoundWrapper>
  )
}

export default SoundWave
