import styled from 'styled-components'
import {DefaultTextColor, DisabledTextColor} from '../../ui/palette'

type Props = {
  current: string | number
  all: string | number
}

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`

const Separator = styled.div`
  ${DisabledTextColor}

  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  margin: 0 8px;
`
const Text = styled.div<{selected?: boolean}>`
  ${(props) => (props.selected ? DefaultTextColor : DisabledTextColor)}
`

const Counter: React.FC<Props> = ({current, all}) => {
  return (
    <Wrapper>
      <Text selected>{current}</Text>
      <Separator>|</Separator>
      <Text>{all}</Text>
    </Wrapper>
  )
}

export default Counter
