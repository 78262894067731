import styled from 'styled-components'
import {DefaultTextColor} from '../../ui/palette'
import {device, isTablet} from '../../ui/device'
import CustomOption from '../../components/Option'

const FOOTER_BOTTOM_POSITION = isTablet() ? 100 : 60

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;

  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  justify-content: center;
  padding: 0 32px;

  background-blend-mode: lighten;
  transition: background 0.5s ease-out;

  @media ${device.tablet} {
    align-items: center;
    padding: 0 48px;
  }
`

const QuestionTitle = styled.div`
  font-weight: 200;
  text-transform: uppercase;
  margin-bottom: 34px;
  ${DefaultTextColor}

  @media ${device.tablet} {
    font-size: 23px;
    line-height: 29px;
  }
`

const OptionsWrapper = styled.div`
  display: flex;
  width: 100%;

  flex-direction: column;
  align-items: start;
  height: 50vh;

  @media ${device.tablet} {
    flex-direction: row;
    align-items: center;
    margin-bottom: 100px;
    height: auto;
    text-align: center;
    justify-content: center;
  }
`

const CounterWrapper = styled.div`
  position: absolute;
  bottom: ${FOOTER_BOTTOM_POSITION - 12}px;
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${device.tablet} {
    bottom: ${FOOTER_BOTTOM_POSITION}px;
  }
`
const Option = styled(CustomOption)`
  margin-bottom: 35px;

  @media ${device.tablet} {
    margin-bottom: 0px;
    margin-right: 63px;
  }
`

export {
  Wrapper,
  QuestionTitle,
  CounterWrapper,
  OptionsWrapper,
  FOOTER_BOTTOM_POSITION,
  Option,
}
