import styled from 'styled-components'
import {device} from '../../ui/device'

const Layout = styled.div<{backgroundImage?: string}>`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  background-image: url(${(props) => props.backgroundImage});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Icon = styled.div<{url: string}>`
  width: 70px;
  height: 70px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.url});
  top: -4%;
  left: 33%;
  position: absolute;
  @media ${device.tablet} {
    top: 0%;
    left: 40%;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

export {Icon, Content, Layout}
