import {createBrowserRouter} from 'react-router-dom'
import {createRef} from 'react'
import MainPage from '../pages/MainPage'
import QuizPage from '../pages/QuizPage'
import ResultPage from '../pages/ResultPage'
import App from '../App'

const routes = [
  {
    path: '/',
    element: <MainPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: '/quiz',
    element: <QuizPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
  {
    path: '/result',
    element: <ResultPage />,
    nodeRef: createRef<HTMLDivElement>(),
  },
]

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: routes.map((route) => ({
      index: route.path === '/',
      path: route.path === '/' ? undefined : route.path,
      element: route.element,
    })),
  },
])

export {router, routes}
