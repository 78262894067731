import {useContext} from 'react'
import {SoundContext} from '../contexts'

const useSound = () => {
  const context = useContext(SoundContext)

  return context
}

export default useSound
