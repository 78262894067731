import {useEffect, useRef} from 'react'
import useVideoBackground from '../../hooks/useVideoBackground'
import {VideoFocusShadow, Video, VideoShadow, VideoWrapper} from './styled'

const VideoBackground = () => {
  const {video} = useVideoBackground()
  const videoRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    const videoElem = videoRef.current

    if (!videoElem) return

    const startPlay = () => {
      videoElem.play()
    }

    // videoElem.addEventListener('canplaythrough', startPlay)
    // videoElem.addEventListener('canplay', startPlay)

    videoElem.style.setProperty('opacity', '0.5')

    setTimeout(() => {
      videoElem.style.setProperty('opacity', '1')
    }, 500)
  }, [video])

  return (
    <VideoWrapper>
      <Video ref={videoRef} muted src={video} autoPlay loop />
      <VideoShadow />
      <VideoFocusShadow />
    </VideoWrapper>
  )
}

export default VideoBackground
