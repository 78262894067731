import styled, {css} from 'styled-components'
import {device} from '../../ui/device'

const fullScreenStyles = css`
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
`
const VideoWrapper = styled.div`
  ${fullScreenStyles}
  z-index: -1;
`
const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: all 0.5s ease-out;
`

const VideoShadow = styled.div`
  ${fullScreenStyles}
  z-index: 1;
  background: radial-gradient(
    96.48% 96.48% at 58.06% 5.13%,
    rgba(0, 0, 0, 0.81) 0%,
    #976130 75.32%,
    #d74d00 100%
  );
  background-blend-mode: multiply;
  opacity: 0.7;

  @media ${device.tablet} {
    background: radial-gradient(
      96.48% 96.48% at 58.06% 5.13%,
      rgba(20, 16, 3, 0.81) 0%,
      #975530 100%
    );
    opacity: 0.8;
  }
`
const VideoFocusShadow = styled.div`
  ${fullScreenStyles}
  z-index: 2;

  background: radial-gradient(
    26.09% 36.52% at 50% 42.19%,
    rgba(20, 16, 3, 0) 0%,
    rgba(13, 10, 2, 0.371723) 32.21%,
    rgba(6, 5, 1, 0.701697) 61.86%,
    #000000 100%
  );
  background-blend-mode: multiply;
  opacity: 0.8;
`

export {VideoFocusShadow, Video, VideoShadow, VideoWrapper}
