export default class Page {
  public name!: string

  public backgroundImage?: {s?: string; m: string}
  public title?: string
  public sound?: string
  public video?: string

  constructor(data: Partial<Page>) {
    Object.assign(this, data)
  }
}
