import Result from '../data/Result'

const results = [
  new Result({
    title: 'Passion',
    description:
      'You like to be the center of attention and to take control of your pleasures',
    sound:
      'https://ia800108.us.archive.org/14/items/78_when-a-woman-loves-a-man_billie-holiday-and-her-orchestra-billie-holiday-buck-clayt_gbia0031202/06%20-%20A%20Sailboat%20in%20the%20Moonlight%20-%20Billie%20Holiday%20and%20her%20Orchestra.mp3',
  }),
]

const useResult = () => {
  // handle results

  return results[0]
}

export default useResult
