import {useLocation} from 'react-router-dom'
import Page from '../data/Page'
import {useMemo} from 'react'

export const pages = [
  new Page({
    // зажигалка
    name: 'main page',
    backgroundImage: {
      s: 'https://imgtr.ee/images/2023/05/30/1ZCpc.png',
      m: 'https://imageupload.io/ib/KoLcMu0Q919ruKP_1684657400.png',
    },
    video:
      'https://ia902607.us.archive.org/35/items/pexels-anastasia-shuraeva-6236526-4096x-2160-24fps-2/pexels-kelly-2548212-3840x2160-24fps%20%281%29.mp4',
    sound:
      'https://ia902606.us.archive.org/25/items/pexels-cottonbro-studio-4156329-4096x-2160-50fps-1_202305/audio.mp3',
  }),
  new Page({
    name: 'quiz page',
    //подружки
    video:
      'https://ia802607.us.archive.org/35/items/pexels-anastasia-shuraeva-6236526-4096x-2160-24fps-2/pexels-anastasia-shuraeva-6236526-4096x2160-24fps%20%281%29.mp4',
    sound:
      'https://ia902606.us.archive.org/25/items/pexels-cottonbro-studio-4156329-4096x-2160-50fps-1_202305/audio.mp3',
  }),
  new Page({
    name: 'result page',
    title: 'Your sensual self is..',
    backgroundImage: {
      s: 'https://imgtr.ee/images/2023/05/30/1ZCpc.png',
      m: 'https://imageupload.io/ib/KoLcMu0Q919ruKP_1684657400.png',
    },
    // ноги
    video:
      'https://ia802607.us.archive.org/35/items/pexels-anastasia-shuraeva-6236526-4096x-2160-24fps-2/output%28compress-video-online.com%29.mp4',
    // sound:
    //   'https://ia902606.us.archive.org/25/items/pexels-cottonbro-studio-4156329-4096x-2160-50fps-1_202305/audio.mp3',
  }),
]

function getIndexPage(pathname: string) {
  if (pathname === '/quiz') {
    return 1
  }

  if (pathname === '/result') {
    return 2
  }

  return 0
}

const useCurrentPage = () => {
  const location = useLocation()
  const index = getIndexPage(location.pathname)

  const page = useMemo(() => pages[index], [index])

  return page
}

export default useCurrentPage
