import styled from 'styled-components'
import SoundIcon from '../../assets/sound.svg'
import SoundOffIcon from '../../assets/sound-off.svg'
import Button from '../Button'
import {DisabledTextColor} from '../../ui/palette'
import SoundTrack from '../SoundTrack'
import {device, isTablet} from '../../ui/device'
import useSound from '../../hooks/useSound'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  z-index: 1;
`

const Icon = styled.div<{url: string}>`
  width: 25px;
  height: 21px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url(${(props) => props.url});

  @media ${device.tablet} {
    width: 21px;
    height: 17px;
    margin-right: 8px;
  }
`

const Separator = styled.div`
  ${DisabledTextColor}
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  margin: 0 8px;
`

const ButtonContent = styled.span`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    &:hover ${Icon} {
      opacity: 0.4;
    }
  }
`

const SoundSwitcher = () => {
  const {mute, setMute} = useSound()

  return (
    <Wrapper>
      {isTablet() ? (
        <>
          <Button
            onClick={() => setMute(false)}
            variant={mute ? 'disabled' : 'default'}
          >
            <ButtonContent>
              <Icon url={mute ? SoundOffIcon : SoundIcon} />
              On
            </ButtonContent>
          </Button>
          <Separator>|</Separator>
          <Button
            onClick={() => setMute(true)}
            variant={mute ? 'default' : 'disabled'}
          >
            Off
          </Button>
        </>
      ) : (
        <Button
          onClick={() => setMute((prev) => !prev)}
          variant={mute ? 'disabled' : 'default'}
        >
          <Icon url={mute ? SoundOffIcon : SoundIcon} />
        </Button>
      )}
      <SoundTrack mute={mute} />
    </Wrapper>
  )
}

export default SoundSwitcher
