import useData from '../../hooks/useData'
import useResult from '../../hooks/useResult'
import {
  PageTitle,
  PlayButton,
  ResultDescription,
  ResultTitle,
  ResultWrapper,
  Layout,
} from './styled'
import useMediaObserver from '../../hooks/useMediaObserver'
import useSound from '../../hooks/useSound'
import {useEffect} from 'react'

const ResultPage = () => {
  const {title: resultTitle, description, sound, video} = useResult()
  const {image, title} = useData()
  useMediaObserver({video})
  const {setSound, pause, setPause} = useSound()

  useEffect(() => {
    if (sound) {
      setPause(true)
      setSound(sound)
    }
  }, [])
  // if no options redirect to quiz

  return (
    <Layout image={image}>
      {title && <PageTitle>{title}</PageTitle>}
      <ResultWrapper>
        <ResultTitle>{resultTitle}</ResultTitle>
        {description && <ResultDescription>{description}</ResultDescription>}
      </ResultWrapper>
      {sound && (
        <PlayButton onClick={() => setPause((prev) => !prev)} pause={pause} />
      )}
    </Layout>
  )
}

export default ResultPage
