import React from 'react'
import logo from '../../assets/logo.svg'
import {Image, LogoWrapper, Description, Button} from './styled'
import useData from '../../hooks/useData'
import {Link} from 'react-router-dom'
import SoundWave from '../../components/SoundWave'
import DefaultLayout from '../../components/DefaultLayout'
import useMediaObserver from '../../hooks/useMediaObserver'

type Props = {}

const MainPage: React.FC<Props> = () => {
  const {image} = useData()
  useMediaObserver()

  return (
    <DefaultLayout image={image}>
      <LogoWrapper>
        <Image src={logo} />
        <Description>Find your sensual self</Description>
      </LogoWrapper>

      <Link to={'/quiz'}>
        <Button variant="outline">Start</Button>
      </Link>
      <SoundWave />
    </DefaultLayout>
  )
}

export default MainPage

// https://imageupload.io/ib/KoLcMu0Q919ruKP_1684657400.png
// https://imageupload.io/ib/1BOEnRo5IzAoKWK_1684566011.png
