import {useContext} from 'react'
import {VideoContext} from '../contexts'

const useVideoBackground = () => {
  const {video, setVideo} = useContext(VideoContext)

  return {video, setVideo}
}

export default useVideoBackground
