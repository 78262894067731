import {css} from 'styled-components'

const textSettings = css`
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`

const defaultColor = css`
  background: linear-gradient(
    122.15deg,
    #feffe2 9.69%,
    #e6d189 90.36%,
    #291a15 144.67%
  );
`
export const DefaultTextColor = css`
  ${defaultColor}
  ${textSettings}
`

export const SecondaryTextColor = css`
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    linear-gradient(122.15deg, #918986 9.69%, #e6b489 90.36%, #291a15 144.67%);
  ${textSettings}
`

export const DisabledTextColor = css`
  ${DefaultTextColor}

  opacity: 0.4;
`

export const DisabledBackgroundColor = css`
  ${defaultColor}

  opacity: 0.4;
`
