import styled from 'styled-components'
import {DefaultTextColor} from '../../ui/palette'
import {device} from '../../ui/device'
import DefaultLayout from '../../components/DefaultLayout'
import playIcon from '../../assets/play.svg'
import pauseIcon from '../../assets/pause.svg'

const PageTitle = styled.div`
  font-weight: 200;
  font-size: 23px;
  line-height: 29px;
  text-transform: uppercase;
  margin-bottom: 18px;
  ${DefaultTextColor}
`

const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 65px;
`

const ResultTitle = styled.div`
  font-family: 'Branch';
  font-weight: 400;
  transition: all 2s;
  color: #e3f36c;

  font-size: 70px;
  line-height: 112%;

  &::first-letter {
    font-family: 'Dirtyline36Daysoftype2022';
    font-weight: 400;
    text-transform: capitalize;
    font-feature-settings: 'salt' on, 'ss01' on, 'ss03' on;
  }

  @media ${device.tablet} {
    font-size: 160px;
  }
`
const ResultDescription = styled.div`
  font-weight: 200;
  font-size: 20px;
  line-height: 26px;
  opacity: 0.6;

  ${DefaultTextColor}

  max-width: 388px;
  text-align: center;
`

const PlayButton = styled.button<{pause: boolean}>`
  width: 108px;
  height: 108px;
  border-radius: 100%;
  border: 1px solid #e3f36c;
  background: transparent
    url(${(props) => (props.pause ? playIcon : pauseIcon)}) no-repeat
    ${(props) => props.pause && '43px'} center;
  cursor: pointer;
  transition: all 0.2s ease-out;

  &:hover {
    transform: scale(1.1);
  }
`
const Layout = styled(DefaultLayout)`
  padding: 0 32px;
`

export {
  Layout,
  PageTitle,
  PlayButton,
  ResultDescription,
  ResultTitle,
  ResultWrapper,
}
