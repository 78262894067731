import {PropsWithChildren} from 'react'
import {Icon, Content, Layout} from './styled'
import starIcon from '../../assets/star.svg'

type Props = {
  showStar?: boolean
  image?: string
  className?: string
} & PropsWithChildren

const DefaultLayout: React.FC<Props> = ({
  showStar = true,
  image,
  className,
  children,
}) => {
  return (
    <Layout className={className} backgroundImage={image}>
      <Content>
        {showStar && <Icon url={starIcon} />}
        {children}
      </Content>
    </Layout>
  )
}

export default DefaultLayout
