import AnswerOption from './AnswerOption'

export default class Question {
  public title!: string
  public options!: AnswerOption[]

  public description?: string
  public sound?: string
  public video?: string

  constructor(data: Partial<Question>) {
    Object.assign(this, data)
  }
}
