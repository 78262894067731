import {createContext} from 'react'

export const VideoContext = createContext<{
  video: string
  setVideo: (src: string) => void
}>({video: '', setVideo: () => {}})

export const SoundContext = createContext<{
  sound: string
  setSound: (src: string) => void
  mute: boolean
  setMute: React.Dispatch<React.SetStateAction<boolean>>
  pause: boolean
  setPause: React.Dispatch<React.SetStateAction<boolean>>
}>({
  sound: '',
  setSound: () => {},
  mute: true,
  setMute: () => {},
  pause: true,
  setPause: () => {},
})
