import Counter from '../../components/Counter'
import useQuestions from '../../hooks/useQuestions'
import {useCallback, useEffect, useRef, useState} from 'react'
import AnswerOption from '../../data/AnswerOption'
import SoundWave from '../../components/SoundWave'

import throttle from 'lodash.throttle'
import useData from '../../hooks/useData'
import useVideoBackground from '../../hooks/useVideoBackground'
import useSound from '../../hooks/useSound'
import useMediaObserver from '../../hooks/useMediaObserver'
import {
  Wrapper,
  QuestionTitle,
  CounterWrapper,
  OptionsWrapper,
  Option,
  FOOTER_BOTTOM_POSITION,
} from './styled'
import {isTablet} from '../../ui/device'
import useOutsideClick from '../../hooks/useOutsideClick'

const QuizPage = () => {
  const {currentIndex, questions, next} = useQuestions()
  const {setVideo} = useVideoBackground()
  const {setSound} = useSound()
  const {video, sound} = useData()
  const currentQuestion = questions?.[currentIndex]
  const rootRef = useRef<HTMLDivElement | null>(null)
  const optionsRef = useRef<HTMLDivElement | null>(null)

  const [selectedOption, setSelectedOption] = useState<AnswerOption | null>(
    null
  )

  useMediaObserver(currentQuestion)

  const changeVideoThrottle = useCallback(throttle(setVideo, 1000), [setVideo])
  const changeSoundThrottle = useCallback(throttle(setSound, 1000), [setSound])

  const resetSelectedOption = () => {
    setSelectedOption(null)
  }
  useEffect(resetSelectedOption, [currentIndex])
  useOutsideClick(optionsRef, resetSelectedOption)

  const selectOption = (option: AnswerOption) => {
    rootRef.current?.style.setProperty('background', '#F4FF9F')

    setTimeout(() => {
      next(option)
      rootRef.current?.style.setProperty('background', 'none')
    }, 500)
  }

  const onClick = (option: AnswerOption) => {
    if (!isTablet()) {
      if (option === selectedOption && selectedOption) {
        selectOption(option)
      }

      setSelectedOption(option)
      return
    }

    selectOption(option)
  }

  const clearMedia = (option: AnswerOption) => {
    if (option.video) {
      changeVideoThrottle(currentQuestion.video || video || '')
    }

    if (option.sound) {
      changeSoundThrottle(currentQuestion.sound || sound || '')
    }
  }

  if (!questions?.length || !currentQuestion) {
    return null
  }

  return (
    <div>
      <Wrapper ref={rootRef}>
        <QuestionTitle>{currentQuestion.title}</QuestionTitle>
        <OptionsWrapper ref={optionsRef}>
          {currentQuestion.options.map((option) => (
            <Option
              key={option.title}
              option={option}
              blur={Boolean(selectedOption && selectedOption !== option)}
              selected={selectedOption === option}
              clearMedia={clearMedia}
              onSelect={onClick}
              changeVideo={changeVideoThrottle}
              changeSound={changeSoundThrottle}
            />
          ))}
        </OptionsWrapper>
      </Wrapper>
      <CounterWrapper>
        <Counter current={currentIndex + 1} all={questions.length} />
      </CounterWrapper>
      <SoundWave bottomPosition={FOOTER_BOTTOM_POSITION} />
    </div>
  )
}

export default QuizPage
