import {FC, memo, useEffect, useRef} from 'react'
import useSound from '../../hooks/useSound'

type SoundTrackProps = {
  mute?: boolean
}

const SoundTrack: FC<SoundTrackProps> = () => {
  const {sound, mute, pause} = useSound()
  const ref = useRef<HTMLAudioElement | null>(null)

  useEffect(() => {
    if (!pause && sound) {
      ref.current?.play()
    } else {
      ref.current?.pause()
    }
  }, [sound, pause])

  useEffect(() => {
    if (!mute && sound) {
      ref.current?.play()
    }
  }, [mute, sound])

  // https://developer.chrome.com/blog/autoplay/
  return (
    <audio ref={ref} autoPlay muted={mute} loop preload="auto" src={sound} />
  )
}

export default memo(SoundTrack)
