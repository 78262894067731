import React from 'react'
import {useOutlet} from 'react-router-dom'
import Header from './components/Header'
import GlobalStyles from './globalStyles'
import VideoBackground from './components/VideoBackground'
import MediaWrapper from './components/MediaWrapper'
import usePreloadMedia from './hooks/usePreloadMedia'

const App = () => {
  // const location = useLocation()
  const currentOutlet = useOutlet()
  usePreloadMedia()
  // const {nodeRef} =
  //   routes.find((route) => route.path === location.pathname) ?? {}

  return (
    <div>
      <MediaWrapper>
        <Header />
        <VideoBackground />
        {/* <div className="page" ref={nodeRef}> */}
        {currentOutlet}
        {/* </div> */}
      </MediaWrapper>

      <GlobalStyles />
    </div>
  )
}

export default App
