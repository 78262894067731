import AnswerOption from '../../data/AnswerOption'
import {isTablet} from '../../ui/device'
import {
  OptionWrapper,
  OptionDescription,
  OptionTitle,
  OptionInfo,
  ContinueButton,
  ArrowIcon,
} from './styled'

type Props = {
  option: AnswerOption
  changeSound: (src: string) => void
  changeVideo: (src: string) => void
  blur: boolean
  selected: boolean
  onSelect: (option: AnswerOption) => void
  clearMedia: (option: AnswerOption) => void
  className?: string
}

const Option: React.FC<Props> = ({
  option,
  blur,
  selected,
  changeSound,
  changeVideo,
  onSelect,
  clearMedia,
  className,
}) => {
  const styledProps = isTablet() ? {} : {selected, blur}

  const onMouseOver = () => {
    if (option.video) {
      changeVideo(option.video)
    }

    if (option.sound) {
      changeSound(option.sound)
    }
  }

  return (
    <OptionWrapper
      className={className}
      key={option.title}
      {...styledProps}
      onMouseOver={onMouseOver}
      onMouseOut={() => clearMedia(option)}
      onClick={() => onSelect(option)}
    >
      <OptionInfo>
        <OptionTitle>{option.title}</OptionTitle>
        {option.description && (
          <OptionDescription>{option.description}</OptionDescription>
        )}
      </OptionInfo>
      {selected && (
        <ContinueButton>
          Continue
          <ArrowIcon />
        </ContinueButton>
      )}
    </OptionWrapper>
  )
}

export default Option
