import {pages} from './useCurrentPage'
import {questions} from './useQuestions'

function createPleloadLink(src: string, type: 'video' | 'audio') {
  const link = document.createElement(type)
  link.setAttribute('preload', 'auto')
  link.setAttribute('src', src)

  return link
}

const cache = new Set<string>()

function preloadSrc(src: string, type: 'video' | 'audio') {
  if (cache.has(src)) {
    return
  }

  cache.add(src)

  const link = createPleloadLink(src, type)

  link.addEventListener('loadeddata', () => document.head.removeChild(link))

  document.head.appendChild(link)
}

function mediaHandler(item: {video?: string; sound?: string}) {
  const {video, sound} = item
  if (video) {
    preloadSrc(video, 'video')
  }

  if (sound) {
    preloadSrc(sound, 'audio')
  }
}

const usePreloadMedia = () => {
  pages.map(mediaHandler)

  questions.map((question) => {
    mediaHandler(question)

    question.options?.map(mediaHandler)
  })
}

export default usePreloadMedia
