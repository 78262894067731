import {useEffect} from 'react'
import useData from './useData'
import useVideoBackground from './useVideoBackground'
import useSound from './useSound'

const useMediaObserver = (props?: {video?: string; sound?: string}) => {
  const pageData = useData()

  const {setVideo} = useVideoBackground()
  const {setSound} = useSound()

  const sound = props?.sound || pageData.sound
  const video = props?.video || pageData.video

  useEffect(() => (video ? setVideo(video) : undefined), [video])
  useEffect(() => (sound ? setSound(sound) : undefined), [sound])
}

export default useMediaObserver
