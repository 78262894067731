import {ButtonHTMLAttributes, FC} from 'react'
import styled from 'styled-components'
import {DefaultTextColor, SecondaryTextColor} from '../../ui/palette'
import {device} from '../../ui/device'

type StyledProps = {
  variant?: 'default' | 'outline' | 'disabled'
  className?: string
  transformText?: boolean
}

type ButtonProps = StyledProps & ButtonHTMLAttributes<HTMLButtonElement>

const StyledButton = styled.button<StyledProps>`
  transition: all 0.2s ease-out;

  display: flex;
  align-items: center;
  justify-content: center;

  font-family: inherit;

  ${(props) => props.variant === 'disabled' && 'opacity: 0.4;'}

  ${(props) =>
    props.variant === 'outline'
      ? `
      border: 1px solid #e3f36c; 
      border-radius: 100%;  
      &:hover {background: #e3f36c;}
      &:pressed {background: #e3f36c;}`
      : 'border: none;'}

  text-align: center;
  background: transparent;
`

const Content = styled.span`
  ${DefaultTextColor}

  font-size: 22px;
  line-height: 29px;
  font-weight: 300;

  @media ${device.tablet} {
    ${StyledButton}:hover & {
      ${SecondaryTextColor}
    }
  }
`

const Button: FC<ButtonProps> = ({children, ...props}) => {
  return (
    <StyledButton {...props}>
      <Content>{children}</Content>
    </StyledButton>
  )
}

export default Button
