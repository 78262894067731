import {PropsWithChildren, useState} from 'react'
import {SoundContext, VideoContext} from '../../contexts'
import useData from '../../hooks/useData'

const MediaWrapper: React.FunctionComponent<PropsWithChildren> = ({
  children,
}) => {
  const {video: defaultVideo, sound: defaultSound} = useData()
  const [video, setVideo] = useState(defaultVideo || '')
  const [sound, setSound] = useState(defaultSound || '')
  const [muteSound, setMuteSound] = useState(true)
  const [pauseSound, setPauseSound] = useState(true)

  return (
    <VideoContext.Provider value={{video, setVideo}}>
      <SoundContext.Provider
        value={{
          sound,
          setSound,
          mute: muteSound,
          setMute: setMuteSound,
          pause: pauseSound,
          setPause: setPauseSound,
        }}
      >
        {children}
      </SoundContext.Provider>
    </VideoContext.Provider>
  )
}

export default MediaWrapper
