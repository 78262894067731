import styled from 'styled-components'
import logo from '../../assets/logo.svg'
import SoundSwitcher from '../SoundSwitcher'
import {Link} from 'react-router-dom'
import {device} from '../../ui/device'

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 32px;
  position: relative;
  align-items: center;
  z-index: 1;
  @media ${device.tablet} {
    padding: 48px;
  }
`
const Logo = styled.img`
  width: 78px;
  height: 31px;
  position: relative;
  top: 8px;

  @media ${device.tablet} {
    width: 114px;
    height: 62px;
  }
`

const Header = () => {
  return (
    <HeaderWrapper>
      <Link to="/">
        <Logo src={logo} />
      </Link>

      <SoundSwitcher />
    </HeaderWrapper>
  )
}

export default Header
